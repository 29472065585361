.dd-custom-menu {
  cursor: pointer;
  background-color: #fff;
  position: absolute;
  border: 1px solid #d7dfe9;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 10px;
  z-index: 99;
  right: 43px;
  padding: 10px;
  z-index: 99;
}
.dd-custom-toggle.dropdown-toggle {
  border: none !important;
}
.dd-custom-toggle.dropdown-toggle::after {
  content: none !important;
}
.dd-limit-toggle.dropdown-toggle::after {
  content: none !important;
}
.dd-custom-items.dropdown-item:hover,
.dd-custom-items.dropdown-item:focus {
  background-color: #7f56d9 !important;
  color: #fff !important;
}

.status-dd.dropdown-toggle {
  border: none !important;
}
.dd-status-items.dropdown-item:hover,
.dd-status-items.dropdown-item:focus {
  background-color: #7f56d9 !important;
  color: #fff !important;
}
.status-dd.dropdown-toggle::after {
  content: none !important;
}

.dropdown-menu.show {
  display: block;
  border-radius: 10px !important;
}
