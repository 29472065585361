.container {
  //   text-align: center;
}
.toggle-switch {
  position: relative;
  width: 44px;
  display: inline-block;
  text-align: left;
  margin: 0 24px;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #f2f4f7;
  border-radius: 20px;
  width: 44px;
  height: 24px;
  background: #f2f4f7;
  border-radius: 12px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 36px;
  padding: 0;
  line-height: 36px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "";
  padding-left: 10px;
  background-color: #7f56d9;
  color: #fff;
}
.inner:after {
  content: "";
  padding-right: 10px;
  background-color: #f2f4f7;
  color: #fff;
  text-align: right;
}
.switch {
  display: block;
  width: 20px;
  height: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  margin: 5px;
  position: absolute;
  top: -3px;
  bottom: 0;
  right: 15px;
  border: 0 solid #f2f4f7;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: -2px;
}
