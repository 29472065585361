body {
  background-color: #f9f9fa;
}

.container {
  // margin-top: 100px;
}
.custom-modal .modal-dialog {
  margin-right: 0;
  margin-left: auto;
  position: relative;
  width: auto;
  pointer-events: none;
  height: 100%;
}
.modal.fade .modal-bottom,
.modal.fade .modal-left,
.modal.fade .modal-right,
.modal.fade .modal-top {
  position: fixed;
  z-index: 1055;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  max-width: 100%;
}
.modal-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}
.modal.fade .modal-right {
  left: auto !important;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.modal.fade.show .modal-bottom,
.modal.fade.show .modal-left,
.modal.fade.show .modal-right,
.modal.fade.show .modal-top {
  transform: translate3d(0, 0, 0);
}
.w-xl {
  width: 320px;
}
.custom-modal .modal-content {
  border-radius: 0;
  height: 100%;
}
.modal-content,
.modal-footer,
.modal-header {
  border: none;
}

.h-100 {
  height: 100% !important;
}

.list-group.no-radius .list-group-item {
  border-radius: 0 !important;
}

.btn-light {
  color: #212529;
  background-color: #f5f5f6;
  border-color: #f5f5f6;
}

.btn-light:hover {
  color: #212529;
  background-color: #e1e1e4;
  border-color: #dadade;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid rgba(160, 175, 185, 0.15);
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal.show .modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
}
.input-cross {
  position: absolute;
  top: 7px;
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  height: 42px !important;
  font-size: 16px;
  color: #3f4247;
  cursor: pointer;
}
.search-input {
  width: 100% !important;
  border: 1px solid #d7dfe9 !important;
  border-radius: 4px !important;
  padding: 0 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  height: 42px !important;
  font-size: 16px;
  color: #3f4247;
}
.search-input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  direction: rtl;
  color: #99a5b4;
}

.form-input {
  width: 100% !important;
  border: 1px solid #d7dfe9 !important;
  border-radius: 4px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  height: 42px !important;
  font-size: 16px;
  color: #3f4247;
  padding: 0 15px;
}

@media (max-width: 992px) {
  .form-input {
    width: 100% !important;
  }
}
