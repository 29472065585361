.slide-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.slide-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}
.slide-exit {
  opacity: 1;
  transform: translateY(0);
}
.slide-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 500ms, transform 500ms;
}
