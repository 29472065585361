.textarea-input {
  background: #ffffff;
  font-family: "Inter";
  border: 1px solid #dfe1e6 !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 6px !important;
  padding: 9px 13px;
  margin-bottom: 24px;
  width: 100% !important;
  &:hover {
    border: 1px solid #6941c6;
    outline: none;
  }
  &:focus {
    border: 1px solid #6941c6 !important;
    outline: none !important;
    box-shadow: none !important;
  }
  ::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
  }
}
@media (max-width: 992px) {
  .textarea-input {
    width: 100% !important;
  }
}
