.pagination-page {
  height: 34px;
  cursor: pointer;
  width: 34px;
  border-radius: 50%;
}
.pagination_con {
  height: 38px;
  width: auto;
  padding: 15px;
  border-radius: 19px;
  background-color: #ffffff;
}
.page_selected {
  height: 34px;
  width: 34px;
  color: #fff;
  background-color: #7f56d9;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(11, 19, 54, 0.16);
}
