.nav-tabs {
  background: #ffffff;
  border-bottom: 1px solid #eaecf0 !important;
  outline: none;
  color: #111827;
  margin: 25px 0 32px 0 !important;
}
.nav-link:hover {
  border-color: transparent !important;
}
.nav-link:focus {
  border-color: transparent !important;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-radius: 2px 2px 0px 0px;
  border-bottom: 5px solid;
  border-bottom-color: #7f56d9 !important;
  border-top: none;
  border-left: none;
  border-right: none;
  color: #7f56d9 !important;
}
.nav-link {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #7a869a !important;
}
.PhoneInputInput {
  border: none;
  outline: none;
}
.PhoneInput {
  border: 1px solid #dfe1e6;
  border-radius: 3px;
  padding: 7px;
  height: 42px;
}
.PhoneInput:hover {
  border: 1px solid #7f56d9;
}
